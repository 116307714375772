<template>
  <List el="xsm" style="max-width: 1200px">
    <List el="column-between xsm">
      <Text el="h2">Referrals</Text>
      <Btn
        @click="to('/dashboard/referrals/create')"
        v-if="$store.getters.isAdmin"
        >Create</Btn
      >
    </List>

    <List el="column-between xsm" style="align-items: center">
      <div el="tabs-underline" style="user-select: none">
        <div
          :class="{ active: videos.section == 'all' }"
          @click="changeSection('all')"
        >
          All
        </div>
        <div
          :class="{ active: videos.section == 'optional' }"
          @click="changeSection('optional')"
        >
          Optional
        </div>
        <div
          :class="{ active: videos.section == 'draft' }"
          @click="changeSection('draft')"
          v-if="$store.getters.isAdmin"
        >
          Draft
        </div>
      </div>
      <div el="input-list">
        <label el="input">
          <input
            type="text"
            placeholder="Search..."
            v-model="videos.searchInput"
            el
          />
        </label>
      </div>
    </List>

    <table el="table">
      <thead>
        <tr>
          <th width="150px">Name</th>
          <th width="150px">Status</th>
          <!-- <th>Referred #</th> -->
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in filteredAssignments"
          :key="item"
          @click="to('/dashboard/referrals/view/' + item.id)"
          :class="{ active: $route.params.id == item.id }"
        >
          <td>{{ item.firstName }} {{ item.lastName }}</td>
          <td>{{ item.status }}</td>
        </tr>
        <!-- <tr>
          <td>Lowis</td>
          <td>Lane</td>
        </tr> -->
      </tbody>
    </table>
  </List>
</template>
<script>
import router from "@/router";
let filter = require("lodash/filter");
// let lowerCase = require("lodash/lowerCase");
// let includes = require("lodash/includes");

export default {
  components: {
    // Layout,
  },
  data() {
    return {
      videos: {
        searchInput: "",
        section: "all",
        filters: {
          tag: "all",
          status: "publish",
        },
      },
    };
  },
  methods: {
    changeSection(val) {
      this.videos.section = val;
      this.videos.filters.tag = val;
      this.$store.commit("collapseSection", true);
    },
    formatDate(dateString) {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const d = new Date(`${dateString} 00:00:00`);
      return `${dateString}, ${days[d.getDay()]}`;
    },
    to(path) {
      this.$store.commit("collapseSection", false);
      router.push(path);
    },
  },
  computed: {
    filteredAssignments() {
      // let x = this;
      return filter(
        this.$store.state.referrals.referrers.list,
        function (item) {
          return item;
          // if (x.videos.section === "draft") {
          //   return (
          //     item.status == "draft" &&
          //     lowerCase(`${item.date} ${item.label}`).search(
          //       lowerCase(x.videos.searchInput)
          //     ) > -1
          //   );
          // } else {
          //   return (
          //     includes(item.tags, x.videos.filters.tag) == true &&
          //     item.status == "publish" &&
          //     lowerCase(`${item.date} ${item.label}`).search(
          //       lowerCase(x.videos.searchInput)
          //     ) > -1
          //   );
          // }
        }
      );
    },
  },
  mounted() {
    let pathArr = this.$route.path.split("/");
    let endPath = pathArr.pop();
    if (endPath == "upload") {
      this.$store.commit("collapseSection", false);
    } else {
      this.$store.commit("collapseSection", true);
    }
    this.$store.commit("referrals/loadReferrers");
  },
};
</script>
<style lang="scss" scoped>
</style>
